// POPPINS
@font-face {
  font-family: IBM Plex Sans Arabic;
  font-weight: 300;
  src: url("../fonts/IBM/IBMPlexSansArabic-Light.ttf");
}
@font-face {
  font-family: IBM Plex Sans Arabic;
  font-weight: 400;
  src: url("../fonts/IBM/IBMPlexSansArabic-Regular.ttf");
}
@font-face {
  font-family: IBM Plex Sans Arabic;
  font-weight: 500;
  src: url("../fonts/IBM/IBMPlexSansArabic-Medium.ttf");
}
@font-face {
  font-family: IBM Plex Sans Arabic;
  font-weight: 600;
  src: url("../fonts/IBM/IBMPlexSansArabic-SemiBold.ttf");
}
@font-face {
  font-family: IBM Plex Sans Arabic;
  font-weight: 700;
  src: url("../fonts/IBM/IBMPlexSansArabic-Bold.ttf");
}

/*  */
:root {
  --font-display: IBM Plex Sans Arabic;
  --font-body: IBM Plex Sans Arabic;
}
